import React from "react";

export const listOfMenuItems: string[] = [
  'home',
  'about',
];

export type NavigationProps = {
  activeMenuItem: string;
  setActiveMenuItem: React.Dispatch<React.SetStateAction<string>>;
};
