// React
import React from 'react';

// Models & Interface
// import { NavigationProps } from "../../models/Models";
import { FaCode} from "react-icons/fa";

// Components
// @ts-ignore
import Scramble from 'react-scramble'

// Styles
import styles from './About.module.scss';

export function About() {

  return (
    <div className={styles.about}>
      <div className={styles.aboutContent}>
        <div className={styles.outerLimits}>
          <div className={styles.text}>
            <Scramble
              autoStart
              text='Markus Minkkinen'
              steps={[
                {
                  roll: 25,
                  action: '+',
                  type: 'all',
                },
                {
                  action: '-',
                  type: 'forward',
                },
              ]}
            />
          </div>
          <div className={styles.lowerTextarea}>
            An enthusiastic amateur computer hobbyist
          </div>
        </div>






        <div className={styles.portrait}>
        </div>

        <div className={styles.mycvLink} onClick={() => window.open("cv", "_blank")}>
          <FaCode style={{paddingRight: 8}}/>CV
        </div>

      </div>




    </div>




  );
}
