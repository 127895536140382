// React
import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';

// Models
import { listOfMenuItems } from "./models/Models";

// Components

import { About } from "./components/about/About";
//import { Navigation } from './components/navigation/Navigation';
import { Some } from "./components/some/Some";

// Styles
import './App.scss';

function App() {
  const [activeMenuItem, setActiveMenuItem] = useState<string>(listOfMenuItems[0]);
  const about = useRef() as MutableRefObject<HTMLElement>;

  const allRefs: MutableRefObject<HTMLElement>[] = useMemo(() => [about], []);
  const observer = useMemo(() => {
  return new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: any) => {
      entry.isIntersecting
        ? setActiveMenuItem(entry.target.id)
        : observer.unobserve(entry.target);
    })
  }, {
    threshold: 0.75
  })
  }, [setActiveMenuItem]);

  useEffect(() => {
    const startObserver = () => {
      document.querySelectorAll('section').forEach((section) => {
        observer.observe(section);
      });
    };
    window.addEventListener('wheel', startObserver);

    return () => {
      window.removeEventListener('wheel', startObserver);
    };
  }, [observer]);

  useEffect(() => {
    const currentlyActiveItem = allRefs.filter((obj: MutableRefObject<HTMLElement>) => {
      return obj.current.id === activeMenuItem ? obj : null
    })
    setTimeout(() => {
      currentlyActiveItem[0].current.scrollIntoView({ behavior: 'smooth', block: "start" });
    }, 0)
  }, [activeMenuItem, allRefs]);

  return (
    <div className={'app'}>

      <Some />
      <section ref={about} id={'about'}>
        <About />
      </section>

    </div>
  );
}

export default App;
