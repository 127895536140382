// React
import React from 'react';

// Models & Interface
import { FaDiscord, FaEnvelope, FaFacebook, FaGithub, FaCode, FaLinkedin, FaTwitter } from "react-icons/fa";
import ressu from "../../assets/gen.svg";

// Styles
import styles from './Some.module.scss';

export function Some() {
  return (
    <div className={styles.some}>
      <a target={'_blank'} rel={'noreferrer'} href={'https://github.com/Marsutron'}><FaGithub /></a>
      <a target={'_blank'} rel={'noreferrer'} href={'https://www.facebook.com/markus.minkkinen.9'}><FaFacebook /></a>
      
      <a target={'_blank'} rel={'noreferrer'} href={'https://www.linkedin.com/in/markus-minkkinen-73699323b/'}><FaLinkedin /></a>

      
      
      <a href={'mailto:markusminkkinen@protonmail.com'}><FaEnvelope /></a>
    </div>
  );
}
